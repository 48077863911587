:root {
  --blue: #162e40;
  --dark: #000;
  --gold: #EC4E4F;
  --gray: #424242;
  --sysBg: #293846;
  --white: #fff;
}

.dashCard {
  border-radius: 15px;
  /* box-shadow: 0 7px 6px -1px #777; */
  box-shadow: 0 6px 6px -3px gray;
  /* height: 200px; */
  height: auto;
  background-color: var(--blue);
}

.dashCard h3 {
  color: var(--white);
  margin-top: 5%;
  text-align: center;
}

.dashCard h5 {
  color: var(--white);
}

.dashCard h6 {
  color: var(--white);
  padding: 12px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.dashCard:hover,
h3.class > * {
  background-color: var(--gold);
  color: #fff !important;
  cursor: pointer;
}

.contentCard {
  border-radius: 8px;
  box-shadow: 0 6px 6px -3px gray;
}
